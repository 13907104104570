.card-wrapper {
  border-style: solid;
  border-width: 1px;
  border-radius: 12px;
  border-color: gray;
  padding: 24px;
  margin: 0 auto;
  text-align: center;
  display: table;
  flex-direction: column;
  margin-top: 24px;
}

.button {
  color: white;
  border: 1px solid;
  border-radius: 6px;
  border-color: gray;
  font-size: 16px;
  font-weight: bold;
  padding: 12px;
}

.button:hover {
  color: white;
  border: 5px solid;
  border-radius: 6px;
  border-color: gray;
  font-size: 16px;
  font-weight: bold;
  padding: 12px;
}

#success {
  background-color: dodgerblue;
}

#deny {
  margin-top: 6px;
  background-color: indianred;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  margin: 24px auto;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
